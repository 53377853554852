import React from 'react';
import { formatToReadableDate } from '../../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const StockLedgerDetailBody = ({ dataList }) => (
  <tbody>
    {dataList.map((data) => (
      <tr>
        <td className='left-align'>{data?.date ? formatToReadableDate(data?.date) : null}</td>
        <td className='left-align'>{data?.vouchernumber || ''}</td>
        <td className='left-align'>{data?.customer_title || ''}</td>
        <td className='left-align'>{data?.type || ''}</td>
        <td className='left-align'>{data?.batch || ''}</td>
        <td className='right-align'>{data?.quantity || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.rate || 0)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.dlp || 0)}</td>
        <td className='right-align'>{data?.per_unit_rate || 0}</td>
      </tr>
    ))}
  </tbody>
);

const stockLedgerReportDetailConfig = [
  {
    id: 2,
    label: 'date',
    title: 'Date',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'vouchernumber',
    title: 'Voucher Number',
    className: 'left-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'customer_title',
    title: 'Customer',
    className: 'left-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'type',
    title: 'Transaction type',
    className: 'left-align',
    sortable: false,
  },
  { id: 4, label: 'batch', title: 'Batch', className: 'left-align', sortable: false },
  {
    id: 5,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  { id: 8, label: 'rate', title: 'Rate', className: 'right-align', sortable: false },
  { id: 6, label: 'dlp', title: 'DLP', className: 'right-align', sortable: false },
  {
    id: 7,
    label: 'per_unit_rate',
    title: 'Per Unit Rate',
    className: 'right-align',
    sortable: false,
  },
];

const config = {
  header: stockLedgerReportDetailConfig,
  getTableBody: StockLedgerDetailBody,
};

export default config;
